export function formatNumber(value, decimal = 0){
  return value ? value.toFixed(decimal).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") : value
}

export function formatNumberAcc(value, decimal = 2){
  if(value){
    if(value < 0){
      return '$' + value.toFixed(decimal).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",") 
    }
    return '$' + value.toFixed(decimal).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
  }
  return '$0'
}

export function getPosition(item) {
  switch(item.positionSide){
    case 'SHORT':
      switch(item.side){
        case 'BUY':
          return 'CLOSE ' + item.positionSide;
        case 'SELL':
          return 'OPEN ' + item.positionSide;
      }
      break;
    case 'LONG':
      switch(item.side){
        case 'BUY':
          return 'OPEN ' + item.positionSide;
        case 'SELL':
          return 'CLOSE ' + item.positionSide;
      }
      break;
    case 'BOTH':
      switch(item.side){
        case 'BUY':
          return 'BUY';
        case 'SELL':
          return 'SELL';
      }
      break;
    default:
      return ""
  }
}
