<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="email">Email:</label>
        <input class="form-control" required="" name="email" type="email" v-model="email">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="contact_phone">Password:</label>
        <input class="form-control" required name="password" type="text" v-model="password">
      </div>
      <div class="form-group col-sm-12">
        <label for="apiKey">Binance API Key:</label>
        <input class="form-control" name="apiKey" type="text" v-model="apiKey">
      </div>
      <div class="form-group col-sm-12">
        <label for="apiSecret">Binance Secret Key:</label>
        <input class="form-control" name="apiSecret" type="text" v-model="apiSecret">
      </div>
      <div class="form-group col-sm-12">
        <label for="telegramUserId">Telegram User ID:</label>
        <input class="form-control" name="telegramUserId" type="text" v-model="telegramUserId">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="type">Type:</label>
        <select class="form-control" required name="type" v-model.number="type">
          <option value="1" style="color:black;">Admin</option>
          <option value="2" style="color:black;">User</option>
        </select>
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
        <router-link class="btn btn-default ml-1" to="/users">Cancel</router-link>
      </div>
    </div>
  </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    computed: {
        ...mapState('users', ['user', 'onRequest', 'profile']),
    },
    data(){
      return {
        name: '',
        email: '',
        password: '',
        apiKey: '',
        apiSecret: '',
        telegramUserId: '',
        type: 2,
      }
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
        const {name, email, password, apiKey, apiSecret, telegramUserId, type} = this
        this.add({ name, email, password, apiKey, apiSecret, telegramUserId, type })
      },
      ...mapActions('users', ['add']),
    },
    created() {
      this.$emit('onChildInit', 'Add New User')
    },
  }
</script>
