import axios from '../_helpers/axios';

export const coin_income_datasService = {
    get_all,
    get_detail,
};

async function get_all() {
  return new Promise((resolve, reject) => {
    axios.post('coin_income_datas')
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_detail(data) {
  return new Promise((resolve, reject) => {
    axios.get('coin_income_datas/' + data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
