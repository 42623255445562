import { binance_futures_coinService } from '../_services';

const state = { onRequest: false, binance_futures_coin: []};

const actions = {
    get_all({ dispatch, commit }) {
        commit('request');

        binance_futures_coinService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  binance_futures_coin: state => {
    return state.binance_futures_coin
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.binance_futures_coin = [];
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.binance_futures_coin = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const binance_futures_coin = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
