<template>
  <Nav/>
  <div class="content-wrapper">
    <ContentHeader v-if="textTitle !== ''" :text="textTitle" :addBtn="addBtn"/>
    <div class="content">
      <div class="container-fluid">
        <div v-if="alert.message" :class="`alert ${alert.type}`">{{alert.message}}</div>
        <div class="row">
          <div class="col-12">
            <div v-if="this.currentRouteName !== 'home'" class="card">
              <div class="card-body">
                <router-view v-on:onChildInit="onChildInit"/>
              </div>
            </div>
            <div v-if="deferredPrompt">
              <button @click="install" class="btn btn-info mr-1">
                Get the app!
              </button>
              <button @click="dismiss" class="btn btn-dark">
                No Thanks
              </button>
            </div>
          </div>
        </div>
      </div><!-- /.container-fluid -->
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Nav from './Nav.vue'
  import ContentHeader from './ContentHeader.vue'
  import { initializeApp } from "firebase/app";
  import { getMessaging, getToken } from "firebase/messaging";

  export default {
    components: {
      Nav,
      ContentHeader,
    },
    computed: {
      ...mapState({
          alert: state => state.alert
      }),
      currentRouteName() {
        return this.$route.name;
      }
    },
    data(){
      return {
        textTitle: '',
        addBtn: null,
        deferredPrompt: null,
        firebaseConfig: {
          apiKey: "AIzaSyCnauCyiVu5hO3ybm5_LywOjK_7cnFWtLQ",
          authDomain: "crypto-3f4fd.firebaseapp.com",
          projectId: "crypto-3f4fd",
          storageBucket: "crypto-3f4fd.appspot.com",
          messagingSenderId: "925949613694",
          appId: "1:925949613694:web:5165e5de5c5b6f2a952a2f",
          measurementId: "G-KQ9MGE6RZL"
        }
      }
    },
    methods: {
      ...mapActions({
          clearAlert: 'alert/clear'
      }),
      ...mapActions('users', ['save_token']),
      onChildInit( value, addBtn = null ){
        this.textTitle = value;
        this.addBtn = addBtn;
      },        
      async dismiss() {
        this.deferredPrompt = null;
      },
      async install() {
        this.deferredPrompt.prompt();
      }
    },
    watch: {
      $route (nextState){
        // clear alert on location change
        this.textTitle = ''
        this.addBtn = undefined
        if(nextState.name !== "login"){
          this.clearAlert();
        }
      },        
      currentRouteName(next, prev) {
        if (next !== prev && next !== null) {
          if(next === 'home' || next === '') {
            this.$router.push('/dashboard')
          }
        }
      }
    },
    created() {
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        this.deferredPrompt = e;
      });
      window.addEventListener("appinstalled", () => {
        this.deferredPrompt = null;
      });
      const app = initializeApp(this.firebaseConfig);
      const messaging = getMessaging(app);
      getToken(messaging, { vapidKey: 'BBEp9qatX-4m64pwKf0iP-AUma54lGcF3Lv-DBgn9tD_nP5bw_jXMCx7zbuLWR4S-UgcykZceKENl3IFug848Ac' }).then((currentToken) => {
        if (currentToken) {
          this.save_token({token: currentToken})
        } else {
          console.log('No registration token available. Request permission to generate one.');
          messaging.requestPermission().then(() => {
            console.log('Notification permission granted.');
            messaging.getToken().then((token) => {
              this.save_token({token: token})
            })
          }).catch((err) => {
            console.log('Unable to get permission to notify.', err);
          });
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
      if(this.currentRouteName === 'home') {
        this.$router.push('/dashboard')
      }
    },
  }
</script>
<style>
  .form-group.required label:after {
      color: #d00;
      content: "*";
      margin-left: 5px;
  }
</style>
