<template>
  <div class="row">
    <div class="col-sm-12">
              <div class="tradingview-widget-container">
      <VueTradingView :options="this.options"/>
    </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import VueTradingView from 'vue-trading-view/src/vue-trading-view';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
    },
    data() {
      return {
        options: {
          symbol: "BINANCE:" + this.$route.params.symbol,
          width: "100%",
          theme: "dark",
          locale: "en",
          "timezone": "UTC",
          "autosize": false,
          "style": "1",
          "toolbar_bg": "#f1f3f6",
          "enable_publishing": false,
          "withdateranges": false,
          "hide_side_toolbar": false,
          "allow_symbol_change": false,
          "save_image": false,
          "details": true,
          "studies": [
            "BB@tv-basicstudies",
            "MACD@tv-basicstudies",
            "StochasticRSI@tv-basicstudies",
            "TripleEMA@tv-basicstudies"
          ],
        }
      }
    },
    methods: {
      ...mapActions('alert', ['error', 'clear']),
    },
    async created() {
      this.$emit('onChildInit', this.$route.params.symbol)
    },
    components: {
      VueTradingView,
    },
    watch: {
    }
  }
</script>
