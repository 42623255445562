<template>
  <div class="row">
    <div class="col-sm-12">
      <form @submit.prevent="handleSubmit">
        <div class="row">
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="dateStart">From Date:</label>
            <input class="form-control" required type="date" v-model="dateStart">
          </div>
          <div class="form-group col-sm-12 col-md-6 required">
            <label for="dateEnd">To Date:</label>
            <input class="form-control" required type="date" v-model="dateEnd">
          </div>
          <div class="form-group col-sm-12">
            <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
          </div>
        </div>
      </form>
    </div>
    <div class="col-sm-4">
      <label for="total_trade">Total Trade:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_trade">
    </div>
    <div class="col-sm-4">
      <label for="total_pnl">Total PNL:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_pnl">
    </div>
    <div class="col-sm-4">
      <label for="total_realized_pnl">Total Realized PNL:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_realized_pnl">
    </div>
    <div class="col-sm-4">
      <label for="total_commission">Total Commission:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_commission">
    </div>
    <div class="col-sm-4">
      <label for="total_funding_fee">Total Funding Fee:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_funding_fee">
    </div>
    <div class="col-sm-4">
      <label for="total_insurance_clear">Total Insurance Clear:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_insurance_clear">
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-if="daily_incomes" v-bind:entries="daily_incomes" :columns="columns" :title="'Binance Daily Incomes'"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import moment from 'moment';
  import { formatNumberAcc } from '../_helpers';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('daily_incomes', ['daily_incomes', 'onRequest']),
      columns(){
        return [
            {name: 'dt', text: 'Date', order: 'desc'},
            {name: 'trades', text: 'Trades', raw: {type: 'format_number'}, class: 'text-right'},
            {name: 'pnl', text: 'PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'realizedPnl', text: 'Realized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'commission', text: 'Commission', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'fundingFee', text: 'Funding Fee', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'insuranceClear', text: 'Insurance Clear', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  {event: 'daily_income_detail', text: 'Detail', method: 'get_date', class: 'btn btn-primary'},
                ]
              }
            },
          ]
        }
    },
    data(){
      return {        
        dateStart: moment().startOf('month').format("YYYY-MM-DD"),
        dateEnd: moment().endOf('month').format("YYYY-MM-DD"),
        total_trade: 0,
        total_pnl: 0,
        total_realized_pnl: 0,
        total_commission: 0,
        total_funding_fee: 0,
        total_insurance_clear: 0,
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('daily_incomes', {get_data: 'get_all'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {dateStart, dateEnd} = this
        let start = moment(dateStart).startOf('day').format('x')
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({dateStart:start, dateEnd:end})
      },
    },
    created() {
      this.$emit('onChildInit', 'Binance Daily Incomes')
      this.handleSubmit()
    },
    watch: {
      daily_incomes(next, prev){
        if(next !== prev && next !== null) {
          this.total_pnl = 0
          this.total_trade = 0
          this.total_realized_pnl = 0
          this.total_commission = 0
          this.total_funding_fee = 0
          this.total_insurance_clear = 0
          next.map(item => {
            this.total_pnl += item.pnl
            this.total_trade += item.trades
            this.total_realized_pnl += item.realizedPnl
            this.total_commission += item.commission
            this.total_funding_fee += item.fundingFee
            this.total_insurance_clear += item.insuranceClear
          })
          this.total_pnl = formatNumberAcc(this.total_pnl)
          this.total_realized_pnl = formatNumberAcc(this.total_realized_pnl)
          this.total_commission = formatNumberAcc(this.total_commission)
          this.total_funding_fee = formatNumberAcc(this.total_funding_fee)
          this.total_insurance_clear = formatNumberAcc(this.total_insurance_clear)
        }
      },
    }
  }
</script>
