import { binance_futures_tradeService } from '../_services';

const state = { onRequest: false, binance_futures_trade: [], binance_futures_trade_detail: {} };

const actions = {
    get_detail({ dispatch, commit }, data) {
        commit('request');

        binance_futures_tradeService.get_detail(data)
            .then(
                (resp) => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_all({ dispatch, commit }, data) {
        commit('request');

        binance_futures_tradeService.get_all(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  binance_futures_trade: state => {
    return state.binance_futures_trade
  },
  binance_futures_trade_detail: state => {
    return state.binance_futures_trade_detail
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.binance_futures_trade = [];
        state.binance_futures_trade_detail = {};
    },
    success_data(state, data) {
      state.onRequest = false;
      state.binance_futures_trade_detail = data;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.binance_futures_trade = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const binance_futures_trade = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
