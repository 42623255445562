import axios from '../_helpers/axios';

export const daily_incomesService = {
    get_all,
    get_detail,
};

async function get_all(data) {
  return new Promise((resolve, reject) => {
    axios.post('daily_incomes', data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_detail(data) {
  return new Promise((resolve, reject) => {
    axios.get('daily_incomes/' + data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
