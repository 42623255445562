<template>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <Datatable v-if="daily_income_detail" v-bind:entries="this.futureTrades" :columns="columns" :title="'Trades ' + this.$route.params.dt" />
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-if="daily_income_detail" v-bind:entries="this.futureIncomes" :columns="columns2" :title="'Detail Income ' + this.$route.params.dt" :tableId="'table_2'" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import moment from 'moment'
  import { getPosition } from '../_helpers';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('daily_incomes', ['daily_income_detail', 'onRequest']),
      columns(){
        return [
          {name: 'time', text: 'Date', raw: {type: 'Moment_UTC'}, order: 'desc'},
          {name: 'symbol', text: 'Symbol', raw: {type: 'symbol_image'}},
          {name: 'position', text: 'Position'},
          {name: 'qty', text: 'Qty', raw: {type: 'format_number'}, class: 'text-right'},
          {name: 'avgPrice', text: 'AVG Price', raw: {type: 'format_number_acc'}, class: 'text-right'},
          {name: 'income', text: 'Realized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
          {name: 'commission', text: 'Commission', raw: {type: 'format_number_acc_with_color_reverse'}, class: 'text-right'},
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                {event: 'futures_trades_detail', text: 'Detail', method: 'get', class: 'btn btn-primary'},
              ]
            }
          },
        ]
      },
      columns2(){
        return [
          {name: 'time', text: 'Date', raw: {type: 'Moment_UTC'}, order: 'desc'},
          {name: 'symbol', text: 'Symbol', raw: {type: 'symbol_image'}},
          {name: 'futureIncomeKey', text: 'Type',
            raw: {
              type: 'Sub',
              value: 'incomeType'
            }
          },
          {name: 'income', text: 'Income', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
        ]
      },
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('daily_incomes', {get_data: 'get_detail'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(date){
        if(this.onRequest) return;
        let dt = moment(date, "YYYY-MM-DD").local().format("x")
        this.clear()
        this.get_data(dt)
      },
    },
    data() {
      return {
        futureTrades: [],
        futureIncomes: []
      }
    },
    created() {
      this.$emit('onChildInit', 'Daily Income ' + this.$route.params.dt)
      this.handleSubmit(this.$route.params.dt)
    },
    watch: {
      daily_income_detail(next, prev){
        if(next !== prev && next !== null) {
          this.futureTrades = next.futureTradeDetailWithLogos
          this.futureIncomes = next.futureIncomes
          this.futureTrades.map(item => {
            item.position = getPosition(item)
          })
        }
      }
    }
  }
</script>
