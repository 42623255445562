<template>
  <div class="row mt-1">
    <div class="col-sm-12">
      <label for="modifier">Modifier (%):</label>
      <input class="form-control text" type="text" v-model.number="modifier" @change="recalculate()">
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-sm-12">
      <div class="card card-widget widget-user">
        <div class="card-footer bg-dark">
          <div class="row">
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="unrealized_header" :style="getStyleColor(totalUnrealizedProfit)">{{ formatNumberAcc(totalUnrealizedProfit) }}</h5>
                <span class="description-text">Unrealized PNL</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="margin_header" >{{ formatNumberAcc(totalMarginBalance) }}</h5>
                <span class="description-text">Margin Balance</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="margin_header" >{{ formatNumberAcc(totalMaintMargin) }}</h5>
                <span class="description-text">Maint Margin</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="wallet_header" >{{ formatNumberAcc(totalWalletBalance) }}</h5>
                <span class="description-text">Wallet Balance</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_low) >= 0)">{{ formatNumberAcc(max_low) }}</h5>
                <span class="description-text">Est. PNL on 1Y Data Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header2" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_high) >= 0)">{{ formatNumberAcc(max_high) }}</h5>
                <span class="description-text">Est. PNL on 1Y Data High</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header3" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_low + totalMaintMargin + Math.abs(max_low)) >= 0)">{{ formatNumberAcc(maint_margin_low + totalMaintMargin + Math.abs(max_low)) }}</h5>
                <span class="description-text">Est Required Margin on 1Y Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header4" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_high + totalMaintMargin + Math.abs(max_high)) >= 0)">{{ formatNumberAcc(maint_margin_high + totalMaintMargin + Math.abs(max_high)) }}</h5>
                <span class="description-text">Est Required Margin on 1Y High</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header5" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_low_3m) >= 0)">{{ formatNumberAcc(max_low_3m) }}</h5>
                <span class="description-text">Est. PNL on 3M Data Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header6" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_high_3m) >= 0)">{{ formatNumberAcc(max_high_3m) }}</h5>
                <span class="description-text">Est. PNL on 3M Data High</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header7" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_low_3m + totalMaintMargin  + Math.abs(max_low_3m)) >= 0)">{{ formatNumberAcc(maint_margin_low_3m + totalMaintMargin  + Math.abs(max_low_3m)) }}</h5>
                <span class="description-text">Est Required Margin on 3M Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header8" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_high_3m + totalMaintMargin + Math.abs(max_high_3m)) >= 0)">{{ formatNumberAcc(maint_margin_high_3m + totalMaintMargin + Math.abs(max_high_3m)) }}</h5>
                <span class="description-text">Est Required Margin on 3M High</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header9" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_low_2y) >= 0)">{{ formatNumberAcc(max_low_2y) }}</h5>
                <span class="description-text">Est. PNL on 2Y Data Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header10" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) + parseFloat(max_high_2y) >= 0)">{{ formatNumberAcc(max_high_2y) }}</h5>
                <span class="description-text">Est. PNL on 2Y Data High</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header11" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_low_2y + totalMaintMargin + Math.abs(max_low_2y)) >= 0)">{{ formatNumberAcc(maint_margin_low_2y + totalMaintMargin + Math.abs(max_low_2y)) }}</h5>
                <span class="description-text">Est Required Margin on 2Y Low</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header12" :style="getStyleColorIfTrue(parseFloat(totalWalletBalance) - parseFloat(maint_margin_high_2y + totalMaintMargin + Math.abs(max_high_2y)) >= 0)">{{ formatNumberAcc(maint_margin_high_2y + totalMaintMargin + Math.abs(max_high_2y)) }}</h5>
                <span class="description-text">Est Required Margin on 2Y High</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card bg-white">
        <h4 class="card-header">Positions <span id="position_amt">({{ positions.length }})</span> <span :innerHTML="margin_ratio_txt"></span> <span :innerHTML="last_wallet_txt"></span></h4>
        <span class="text-danger" id="text_error"></span>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="position-table">
              <thead>
                  <tr>
                    <th rowspan="2">Symbol</th>
                    <th rowspan="2">Size</th>
                    <th rowspan="2">Entry Price</th>
                    <th rowspan="2">Mark Price</th>
                    <th rowspan="2">Liq. Price</th>
                    <th rowspan="2">Margin</th>
                    <th rowspan="2">PNL</th>
                    <th rowspan="2">Est. TP</th>
                    <th rowspan="2">Est. SL</th>
                    <th colspan="3" class="text-center">1Y Data</th>
                    <th colspan="3" class="text-center">3M Data</th>
                    <th colspan="3" class="text-center">2Y Data</th>
                  </tr>
                  <tr>
                    <th>Low</th>
                    <th>High</th>
                    <th>Loss & Gain</th>
                    <th>Low</th>
                    <th>High</th>
                    <th>Loss & Gain</th>
                    <th>Low</th>
                    <th>High</th>
                    <th>Loss & Gain</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in positions" :key="index">
                  <td :style="{ 'border-left': '5px solid ' + (item.positionSide === 'SHORT' ? 'red' : 'green') }" class="text-bold">                    
                    <router-link :to="{ name: 'binance_futures_coin_detail', params: {symbol: item.symbol }}" target="_blank">
                      <span><img :src="item.logo" width="20" height="20" /></span> {{ item.symbol }}
                      <span :style="{ color: 'rgb(240, 185, 11)', backgroundColor: 'rgb(54, 50, 29)', fontSize: '12px', marginLeft: '4px', borderRadius: '2px', paddingLeft: '4px', paddingRight: '4px' }">
                        {{ item.leverage }}x
                      </span>
                    </router-link>
                  </td>
                  <td :style="{ color: item.positionSide === 'SHORT' ? 'red' : 'green' }" class="text-right text-bold">{{ item.positionAmt }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_entry'">{{ formatNumber(item.entryPrice, 4) }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_mark'">{{ formatNumber(item.markPrice, 4) }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_liquidation'">
                    {{ item.liquidationPrice > 0 ? formatNumber(item.liquidationPrice, 4) : '--' }}
                  </td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_margin'">{{ formatNumber(item.positionInitialMargin, 2) }}</td>
                  <td :style="{ color: item.unRealizedProfit < 0 ? 'red' : 'green' }" class="text-right">
                    <span>
                      <div :id="item.symbol + '_' + item.positionSide + '_pnl'">{{ formatNumber(item.unRealizedProfit, 2) }}</div>
                      <div :id="item.symbol + '_' + item.positionSide + '_roe'">({{ formatNumber(item.unRealizedProfit / item.positionInitialMargin * 100, 2) }}%)</div>
                    </span>
                  </td>
                  <td class="text-right">
                    <div>{{ item.tp_amt }}</div>
                    <div :style="{ color: item.tp < 0 ? 'red' : 'green' }">{{ item.tp }}</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.sl_amt }}</div>
                    <div :style="{ color: item.sl < 0 ? 'red' : 'green' }">{{ item.sl }}</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.lowest }}</div>
                    <div>{{((parseFloat(item.lowest) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.highest }}</div>
                    <div>{{((parseFloat(item.highest) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div class="text-danger">{{formatNumber(item.low, 2)}}</div>
                    <div class="text-success">{{formatNumber(item.high, 2)}}</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.lowest_3m }}</div>
                    <div>{{((parseFloat(item.lowest_3m) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.highest_3m }}</div>
                    <div>{{((parseFloat(item.highest_3m) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div class="text-danger">{{formatNumber(item.low_3m, 2)}}</div>
                    <div class="text-success">{{formatNumber(item.high_3m, 2)}}</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.lowest_2y }}</div>
                    <div>{{((parseFloat(item.lowest_2y) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.highest_2y }}</div>
                    <div>{{((parseFloat(item.highest_2y) - parseFloat(item.markPrice)) / parseFloat(item.markPrice) * 100).toFixed(2)}}%</div>
                  </td>
                  <td class="text-right">
                    <div class="text-danger">{{formatNumber(item.low_2y, 2)}}</div>
                    <div class="text-success">{{formatNumber(item.high_2y, 2)}}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { formatNumberAcc, formatNumber } from '../_helpers';
  export default {
    computed: {
        ...mapState('binance_futures_accounts', ['binance_futures_accounts']),
        ...mapState('binance_futures_positions', ['binance_futures_positions']),
        ...mapState('binance_futures_klines', ['binance_futures_klines']),
    },
    components:{
    },
    data() {
      return {
        positions: [],
        orders: [],
        klines: [],
        modifier: 0,
        margin_ratio_txt: '',
        last_wallet_txt: '',
        totalMaintMargin: '',
        totalMarginBalance: '',
        totalWalletBalance: '',
        totalUnrealizedProfit: '',
        maint_margin_low: 0,
        maint_margin_low_3m: 0,
        maint_margin_low_2y: 0,
        maint_margin_high: 0,
        maint_margin_high_3m: 0,
        maint_margin_high_2y: 0,
        max_low: 0,
        max_high: 0,
        max_low_3m: 0,
        max_high_3m: 0,
        max_low_2y: 0,
        max_high_2y: 0,
      };
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
      },
      ...mapActions('binance_futures_accounts', {getAccount: 'get_data'}),
      ...mapActions('binance_futures_positions', {getPosition: 'get_data'}),
      ...mapActions('binance_futures_klines', {getKlines: 'get_data'}),
      formatNumberAcc(value, decimal = 4) {
        let val = parseFloat(value)
        return formatNumberAcc(val, decimal)
      },
      formatNumber(value, decimal = 4) {
        let val = parseFloat(value)
        return formatNumber(val, decimal)
      },
      getStyleColor(val) {
        return val > 0 ? 'color: #28a745' : 'color: #dc3545'
      },
      getStyleColorIfTrue(isTrue) {
        return isTrue ? 'color: #28a745' : 'color: #dc3545'
      },
      recalculate() {
        let modifier = this.modifier
        if(modifier === "") {
          return;
        }
        let max_low = 0, max_high = 0, max_low_3m = 0, max_high_3m = 0, max_low_2y = 0, max_high_2y = 0
        this.maint_margin_low = 0
        this.maint_margin_low_3m = 0
        this.maint_margin_low_2y = 0
        this.maint_margin_high = 0
        this.maint_margin_high_3m = 0
        this.maint_margin_high_2y = 0
        this.positions.map( item => {
          var { positionAmt, symbol, entryPrice, positionSide, leverage } = item;
          let lowest = Number.MAX_VALUE;
          let highest = -Number.MIN_VALUE;
          let lowest_3m = Number.MAX_VALUE;
          let highest_3m = -Number.MIN_VALUE;
          let lowest_2y = Number.MAX_VALUE;
          let highest_2y = -Number.MIN_VALUE;
          let klinesArr = this.klines.filter(item2 => item2.symbol == symbol)
          let klines = []
          if(klinesArr.length > 0) {
            klines = klinesArr[0].candleStickLines
          }
          klines.map((item2, index2) =>{
            lowest_2y = Math.min(item2.low * (1 - (modifier / 100.0)), lowest_2y)
            highest_2y = Math.max(item2.high * (1 + (modifier / 100.0)), highest_2y)
            if(index2>=(klines.length - 3)){ //3M
              lowest_3m = Math.min(item2.low * (1 - (modifier / 100.0)), lowest_3m)
              highest_3m = Math.max(item2.high * (1 + (modifier / 100.0)), highest_3m)
            }
            if(index2>=(klines.length - 12)){ //1Y
              lowest = Math.min(item2.low * (1 - (modifier / 100.0)), lowest)
              highest = Math.max(item2.high * (1 + (modifier / 100.0)), highest)
            }
          })
          let low = 0, high = 0, low_3m = 0, high_3m = 0, low_2y = 0, high_2y = 0
          if(positionSide == 'LONG'){
            low = (positionAmt) * (lowest - entryPrice)
            high = (positionAmt) * (highest - entryPrice)
            low_3m = (positionAmt) * (lowest_3m - entryPrice)
            high_3m = (positionAmt) * (highest_3m - entryPrice)
            low_2y = (positionAmt) * (lowest_2y - entryPrice)
            high_2y = (positionAmt) * (highest_2y - entryPrice)
          } else {
            low = (positionAmt) * (highest - entryPrice)
            high = (positionAmt) * (lowest - entryPrice)
            low_3m = (positionAmt) * (highest_3m - entryPrice)
            high_3m = (positionAmt) * (lowest_3m - entryPrice)
            low_2y = (positionAmt) * (highest_2y - entryPrice)
            high_2y = (positionAmt) * (lowest_2y - entryPrice)
          }
          if(positionSide == 'LONG'){
            max_low+=low
            max_high+=high
            max_low_3m+=low_3m
            max_high_3m+=high_3m
            max_low_2y+=low_2y
            max_high_2y+=high_2y
          } else {
            max_low+=high
            max_high+=low
            max_low_3m+=high_3m
            max_high_3m+=low_3m
            max_low_2y+=high_2y
            max_high_2y+=low_2y
          }
          item.lowest = lowest
          item.highest = highest
          item.lowest_3m = lowest_3m
          item.highest_3m = highest_3m
          item.lowest_2y = lowest_2y
          item.highest_2y = highest_2y
          item.low = low
          item.high = high
          item.low_3m = low_3m
          item.high_3m = high_3m
          item.low_2y = low_2y
          item.high_2y = high_2y
          this.maint_margin_low += (lowest * Math.abs(positionAmt) / leverage)
          this.maint_margin_low_3m += (lowest_3m * Math.abs(positionAmt) / leverage)
          this.maint_margin_low_2y += (lowest_2y * Math.abs(positionAmt) / leverage)
          this.maint_margin_high += (highest * Math.abs(positionAmt) / leverage)
          this.maint_margin_high_3m += (highest_3m * Math.abs(positionAmt) / leverage)
          this.maint_margin_high_2y += (highest_2y * Math.abs(positionAmt) / leverage)
        })
        this.max_low = max_low
        this.max_high = max_high
        this.max_low_3m = max_low_3m
        this.max_high_3m = max_high_3m
        this.max_low_2y = max_low_2y
        this.max_high_2y = max_high_2y
      }
    },
    created() {
      this.getAccount();
      this.getPosition();
    },
    watch: {
      binance_futures_accounts(next, prev){
        if(next !== prev && next !== null) {
          if(next.account) {
            let margin_ratio = 0
            this.totalUnrealizedProfit = next.account.totalUnrealizedProfit
            this.totalMaintMargin = next.account.totalMaintMargin
            this.totalMarginBalance = next.account.totalMarginBalance
            this.totalWalletBalance = next.account.totalWalletBalance
            margin_ratio = this.totalMarginBalance > 0 ? next.account.totalMaintMargin / this.totalMarginBalance * 100 : 0;
            this.allTimePnl = next.allTimePnl
            this.todayPnl = next.todayPnl
            this.yesterdayPnl = next.yesterdayPnl
            this.thisWeekPnl = next.thisWeekPnl
            this.thisMonthPnl = next.thisMonthPnl
  
            if(margin_ratio > 0){
              this.margin_ratio_txt = ' - Margin Ratio: <span class="'+(margin_ratio >= 80 ? 'text-red' : (margin_ratio >= 10 ? 'text-orange' : 'text-green'))+'">'+formatNumber(margin_ratio, 2) + '%</span>'
            }
            if(next.lastWallet){
              let last = next.lastWallet.filter(item => item != parseInt(this.totalUnrealizedProfit / 1000))
              if(last.length > 0){
                this.last_wallet_txt = ' - ' + (last[0] < parseInt(this.totalUnrealizedProfit / 1000) ? '<span class="text-green">' : '<span class="text-red">') + ' ('+last[0]+'K)</span>'
              }
            }
          }
        }
      },
      binance_futures_positions(next, prev){
        if(next !== prev && next !== null && next.binanceFuturePosition && next.binanceFutureOrders) {
          this.positions = next.binanceFuturePosition.filter(item => item.notional !== "0")
          this.orders = next.binanceFutureOrders
          let symbols = []
          this.positions.map(item => {
            let order = next.binanceFutureOrders.filter(item2 => item.symbol == item2.symbol)
            const {positionAmt, entryPrice, positionSide, markPrice, leverage, symbol} = item
            order.map(item2 => {
              if(item2.type == 'TAKE_PROFIT_MARKET' && item2.positionSide == positionSide){
                item.tp_amt = item2.stopPrice
                item.tp = this.formatNumber(positionAmt * (item2.stopPrice - entryPrice), 2)
              }
              if(item2.type == 'STOP_MARKET' && item2.positionSide == positionSide){
                item.sl_amt = item2.stopPrice
                item.sl = this.formatNumber(positionAmt * (item2.stopPrice - entryPrice), 2)
              }
              if(item2.type == 'LIMIT' && item2.positionSide == positionSide && ((positionSide == 'LONG' && item2.side == 'SELL') || (positionSide == 'SHORT' && item2.side == 'BUY'))){
                item.tp_amt = item2.price
                item.tp = this.formatNumber(positionAmt * (item2.price - entryPrice), 2)
              }
            })
            symbols.push(symbol)
            item.positionInitialMargin = markPrice * Math.abs(positionAmt) / leverage
          })
          if(symbols.length > 0){
            this.getKlines({symbols: symbols, interval: "1M", limit: 24})
          }
        }
      },
      binance_futures_klines(next, prev){
        if(next !== prev && next !== null) {
          this.klines = next
          this.recalculate()
        }
      }
    }
  }
</script>
