import { binance_futures_klinesService } from '../_services';

const state = { onRequest: false, binance_futures_klines: []};

const actions = {
    get_data({ dispatch, commit }, data) {
        commit('request');

        binance_futures_klinesService.get_data(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  binance_futures_klines: state => {
    return state.binance_futures_klines
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.binance_futures_klines = [];
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.binance_futures_klines = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const binance_futures_klines = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
