import axios from '../_helpers/axios';

export const binance_futures_tradeService = {
    get_all,
    get_detail,
};

async function get_all(data) {
  return new Promise((resolve, reject) => {
    axios.post('binance_future_trades', data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_detail(data) {
  return new Promise((resolve, reject) => {
    axios.get('binance_future_trades/' + data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}