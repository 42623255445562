import axios from '../_helpers/axios';

export const position_stagingsService = {
    get_all,
    get_max_per_day,
};

async function get_all(data) {
  return new Promise((resolve, reject) => {
    axios.post('position_stagings', data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}

async function get_max_per_day(data) {
  return new Promise((resolve, reject) => {
    axios.post('position_stagings_max_per_day', data)
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}
