<template>
  <form @submit.prevent="handleSubmit">
    <div class="row">
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateStart">From Date:</label>
        <input class="form-control" required type="date" v-model="dateStart">
      </div>
      <div class="form-group col-sm-12 col-md-6 required">
        <label for="dateEnd">To Date:</label>
        <input class="form-control" required type="date" v-model="dateEnd">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" required type="submit" value="Show" :disabled="this.onRequest">
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <Datatable v-if="position_stagings" v-bind:entries="position_stagings_max_per_day_data" :columns="columns" :title="'Position Movements (Max per Day)'"/>
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-if="position_stagings" v-bind:entries="position_stagings_data" :columns="columns" :title="'Position Movements (Max per Day)'" :tableId="'table_2'"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import moment from 'moment';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('position_stagings', ['position_stagings', 'position_stagings_max_per_day', 'onRequest']),
      columns(){
        return [
            {name: 'created', text: 'Date', order: 'desc', raw: {type: 'Moment_utc_datetime'}},
            {name: 'totalUnrealizedProfit', text: 'Unrealized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'totalMarginBalance', text: 'Margin Balance', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'totalWalletBalance', text: 'Wallet Balance', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
          ]
        }
    },
    data(){
      return {        
        dateStart: moment().startOf('month').format("YYYY-MM-DD"),
        dateEnd: moment().endOf('month').format("YYYY-MM-DD"),
        position_stagings_data: [],
        position_stagings_max_per_day_data: [],
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('position_stagings', {get_data: 'get_all', get_max_per_day: 'get_max_per_day'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        const {dateStart, dateEnd} = this
        let start = moment(dateStart).startOf('day').format('x')
        let end = moment(dateEnd).endOf('day').format('x')
        this.get_data({dateStart:parseInt(start), dateEnd:parseInt(end)})
        this.get_max_per_day({dateStart:parseInt(start), dateEnd:parseInt(end)})
      },
    },
    created() {
      this.$emit('onChildInit', 'Statistics')
      this.handleSubmit()
    },
    watch: {
      position_stagings(next, prev){
        if(next !== prev && next !== null) {
          this.position_stagings_data = next
        }
      },
      position_stagings_max_per_day(next, prev){
        if(next !== prev && next !== null) {
          this.position_stagings_max_per_day_data = next
        }
      }
    }
  }
</script>
