import { position_stagingsService } from '../_services';

const state = { onRequest: false, position_stagings: [], position_stagings_max_per_day: [] };

const actions = {
    get_all({ dispatch, commit }, data) {
        commit('request');

        position_stagingsService.get_all(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_max_per_day({ dispatch, commit }, data) {
        commit('request');

        position_stagingsService.get_max_per_day(data)
            .then(
                (resp) => {
                    commit('success_data_max_per_day', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  position_stagings: state => {
    return state.position_stagings
  },
  position_stagings_max_per_day: state => {
    return state.position_stagings_max_per_day
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.position_stagings = data;
    },
    success_data_max_per_day(state, data) {
      state.onRequest = false;
      state.position_stagings_max_per_day = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const position_stagings = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
