<template>
  <form @submit.prevent="handleUpdate">
    <div class="row">
      <div class="form-group col-sm-12 required">
          <label for="name">Name:</label>
          <input class="form-control" required="" name="name" type="text" v-model="profile.name">
      </div>
      <div class="form-group col-sm-12 required">
        <label for="email">Email:</label>
        <input class="form-control" disabled="" name="email" type="email" v-model="profile.email">
      </div>
      <div class="form-group col-sm-12">
        <label for="apiKey">Binance API key:</label>
        <input class="form-control" name="apiKey" type="text" v-model="profile.apiKey">
      </div>
      <div class="form-group col-sm-12">
        <label for="apiSecret">Binance API Secret:</label>
        <input class="form-control" name="apiSecret" type="text" v-model="profile.apiSecret">
      </div>
      <div class="form-group col-sm-12">
        <label for="telegramUserId">Telegram User ID:</label>
        <input class="form-control" name="telegramUserId" type="text" v-model="profile.telegramUserId">
      </div>
      <div class="form-group col-sm-12">
        <input class="btn btn-primary" type="submit" value="Save">
      </div>
    </div>
  </form>
  <form @submit.prevent="handleChangePassword">
    <div class="row">
      <div class="form-group col-sm-6 required">
          <label for="password">Password:</label>
          <input class="form-control" required="" name="password" type="password" autocomplete="new-password" v-model="password">
      </div>
      <div class="form-group col-sm-6 required">
        <label for="repassword">Repeat Password:</label>
        <input class="form-control" required="" name="repassword" type="password" autocomplete="new-password" v-model="repassword">
      </div>
      <div class="form-group col-sm-12">
          <input class="btn btn-warning" type="submit" value="Change Password">
      </div>
    </div>
 </form>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    data(){
      return {
        password: '',
        repassword: '',
      }
    },
    computed: {
      ...mapState('users', ['user', 'profile', 'onRequest']),
    },
    methods: {
      handleUpdate(){
        if(this.onRequest) return;
        const {user} = this
        if (user) {
            this.edit_profile({ user: this.profile })
        }
      },
      handleChangePassword(){
        if(this.onRequest) return;
        const {password, repassword} = this
        if (password && repassword) {
            this.change_password({ password, repassword })
        }
      },
      ...mapActions('users', ['edit_profile', 'change_password']),
    },
    created() {
      this.$emit('onChildInit', 'Edit Profile')
    },
  }
</script>
