<template>
  <div class="row" v-if="futureOrder">
    <div class="form-group col-sm-6">
      <label for="symbol">Symbol:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.symbol">
    </div>
    <div class="form-group col-sm-6">
      <label for="time">Time:</label>
      <input class="form-control" type="text" disabled v-model="time">
    </div>
    <div class="form-group col-sm-6">
      <label for="status">Status:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.status">
    </div>
    <div class="form-group col-sm-6">
      <label for="avgPrice">AVG Price:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.avgPrice">
    </div>
    <div class="form-group col-sm-6">
      <label for="origQty">Original Qty:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.origQty">
    </div>
    <div class="form-group col-sm-6">
      <label for="executedQty">Executed Qty:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.executedQty">
    </div>
    <div class="form-group col-sm-6">
      <label for="type">Type:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.type">
    </div>
    <div class="form-group col-sm-6">
      <label for="side">Side:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.side">
    </div>
    <div class="form-group col-sm-6">
      <label for="positionSide">Position Side:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.positionSide">
    </div>
    <div class="form-group col-sm-6">
      <label for="origType">Original Type:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.origType">
    </div>
    <div class="form-group col-sm-6">
      <label for="workingType">Working Type:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.workingType">
    </div>
    <div class="form-group col-sm-6">
      <label for="closePosition">Is Closed:</label>
      <input class="form-control" type="text" disabled v-model="futureOrder.closePosition">
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 mt-3">
      <Datatable v-if="binance_futures_trade_detail" v-bind:entries="futureTrades" :columns="columns" :title="'Binance Futures Trade'"/>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import moment from 'moment';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('binance_futures_trade', ['binance_futures_trade_detail', 'onRequest']),
      columns(){
        return [
            {name: 'time', text: 'Time', order: 'desc', raw: {type: 'Moment_UTC'}},
            {name: 'commission', text: 'Commission', raw: {type: 'format_number_acc_with_color_reverse'}, class: 'text-right'},
            {name: 'realizedPnl', text: 'Realized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'qty', text: 'Qty', raw: {type: 'format_number'}, class: 'text-right'},
            {name: 'price', text: 'Price', raw: {type: 'format_number_acc'}, class: 'text-right'},
            {name: 'quoteQty', text: 'Quote Qty', raw: {type: 'format_number'}, class: 'text-right'},
            {name: 'side', text: 'Side'},
            {name: 'buyer', text: 'Buyer'},
            {name: 'maker', text: 'Maker'},
            {name: 'commissionAsset', text: 'Commission Asset'},
          ]
        },
        time() {
          return this.futureOrder && this.futureOrder.time ? moment.utc(this.futureOrder.time, "x").local().format("YYYY-MM-DD HH:mm:ss") : ""
        }
    },
    data(){
      return {  
        futureOrder: null,
        futureTrades: []
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('binance_futures_trade', {get_data: 'get_detail'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(id){
        if(this.onRequest) return;
        this.clear()
        this.get_data(id)
      },
    },
    created() {
      this.$emit('onChildInit', 'Binance Futures Trade')
      this.handleSubmit(this.$route.params.id)
    },
    watch: {
      binance_futures_trade_detail(next, prev){
        if(next !== prev && next !== null) {
          this.futureOrder = next.futureOrder
          this.futureTrades = next.futureTrades
        }
      },
    }
  }
</script>
