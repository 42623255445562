import { createStore } from 'vuex'
import { alert } from './alert.module';
import { users } from './users.module';
import { binance_futures_trade } from './binance_futures_trade.module';
import { daily_incomes } from './daily_incomes.module';
import { coin_income_datas } from './coin_income_datas.module';
import { position_stagings } from './position_stagings.module';
import { binance_futures_coin } from './binance_futures_coin.module';
import { binance_futures_accounts } from './binance_futures_accounts.module';
import { binance_futures_positions } from './binance_futures_positions.module';
import { binance_futures_klines } from './binance_futures_klines.module';

export const store = createStore({
  modules: {
      alert,
      users,
      binance_futures_trade,
      daily_incomes,
      coin_income_datas,
      position_stagings,
      binance_futures_coin,
      binance_futures_accounts,
      binance_futures_positions,
      binance_futures_klines,
  }
})
