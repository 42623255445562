<template>
  <div class="row mt-1">
    <div class="col-sm-12">
      <div class="card card-widget widget-user">
        <div class="card-footer bg-dark">
          <div class="row">
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="unrealized_header" :style="getStyleColor(totalUnrealizedProfit)">{{ formatNumberAcc(totalUnrealizedProfit) }}</h5>
                <span class="description-text">Unrealized PNL</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="margin_header" >{{ formatNumberAcc(totalMarginBalance) }}</h5>
                <span class="description-text">Margin Balance</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="wallet_header" >{{ formatNumberAcc(totalWalletBalance) }}</h5>
                <span class="description-text">Wallet Balance</span>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="description-block">
                <h5 class="description-header" id="all_time_pnl_header" :style="getStyleColor(allTimePnl)">{{ formatNumberAcc(allTimePnl) }}</h5>
                <span class="description-text">All Time PNL</span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="today_pnl_header" :style="getStyleColor(todayPnl)">{{ formatNumberAcc(todayPnl) }}</h5>
                <span class="description-text">Today PNL</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="yesterday_pnl_header" :style="getStyleColor(yesterdayPnl)">{{ formatNumberAcc(yesterdayPnl) }}</h5>
                <span class="description-text">Yesterday PNL</span>
              </div>
            </div>
            <div class="col-sm-3 border-right">
              <div class="description-block">
                <h5 class="description-header" id="this_week_pnl_header" :style="getStyleColor(thisWeekPnl)">{{ formatNumberAcc(thisWeekPnl) }}</h5>
                <span class="description-text">This Week PNL</span>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="description-block">
                <h5 class="description-header" id="this_month_pnl_header" :style="getStyleColor(thisMonthPnl)">{{ formatNumberAcc(thisMonthPnl) }}</h5>
                <span class="description-text">This Month PNL</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card bg-white">
        <h4 class="card-header">Positions <span id="position_amt">({{ positions.length }})</span> <span :innerHTML="margin_ratio_txt"></span> <span :innerHTML="last_wallet_txt"></span></h4>
        <span class="text-danger" id="text_error"></span>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="position-table">
              <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Size</th>
                    <th>Entry Price</th>
                    <th>Mark Price</th>
                    <th>Liq. Price</th>
                    <th>Margin</th>
                    <th>PNL</th>
                    <th>Est. Comm</th>
                    <th>Est. TP</th>
                    <th>Est. SL</th>
                    <th>%</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in positions" :key="index">
                  <td :style="{ 'border-left': '5px solid ' + (item.positionSide === 'SHORT' ? 'red' : 'green') }" class="text-bold">
                    <router-link :to="{ name: 'binance_futures_coin_detail', params: {symbol: item.symbol }}" target="_blank">
                      <span><img :src="item.logo" width="20" height="20" /></span> {{ item.symbol }}
                      <span :style="{ color: 'rgb(240, 185, 11)', backgroundColor: 'rgb(54, 50, 29)', fontSize: '12px', marginLeft: '4px', borderRadius: '2px', paddingLeft: '4px', paddingRight: '4px' }">
                        {{ item.leverage }}x
                      </span>
                    </router-link>
                  </td>
                  <td :style="{ color: item.positionSide === 'SHORT' ? 'red' : 'green' }" class="text-right text-bold">{{ item.positionAmt }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_entry'">{{ formatNumber(item.entryPrice, 4) }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_mark'">{{ formatNumber(item.markPrice, 4) }}</td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_liquidation'">
                    {{ item.liquidationPrice > 0 ? formatNumber(item.liquidationPrice, 4) : '--' }}
                  </td>
                  <td class="text-right" :id="item.symbol + '_' + item.positionSide + '_margin'">{{ formatNumber(item.positionInitialMargin, 2) }}</td>
                  <td :style="{ color: item.unRealizedProfit < 0 ? 'red' : 'green' }" class="text-right">
                    <span>
                      <div :id="item.symbol + '_' + item.positionSide + '_pnl'">{{ formatNumber(item.unRealizedProfit, 2) }}</div>
                      <div :id="item.symbol + '_' + item.positionSide + '_roe'">({{ formatNumber(item.unRealizedProfit / item.positionInitialMargin * 100, 2) }}%)</div>
                    </span>
                  </td>
                  <td class="text-right"><span>{{ formatNumber((Math.abs(item.entryPrice * item.positionAmt) + Math.abs(item.positionAmt * item.markPrice)) * 0.04 / 100, 2) }}</span></td>
                  <td class="text-right">
                    <div>{{ item.tp_amt }}</div>
                    <div :style="{ color: item.tp < 0 ? 'red' : 'green' }">{{ item.tp }}</div>
                  </td>
                  <td class="text-right">
                    <div>{{ item.sl_amt }}</div>
                    <div :style="{ color: item.sl < 0 ? 'red' : 'green' }">{{ item.sl }}</div>
                  </td>
                  <td class="text-right">
                    <div :style="{ color: item.ppct > 0 ? (item.positionSide === 'LONG' ? 'green' : 'red') : (item.positionSide === 'LONG' ? 'red' : 'green') }">
                      {{ formatNumber(item.ppct, 2) }}%
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card bg-white">
        <h4 class="card-header">Open Order <span id="order_amt">({{ orders.length }})</span></h4>
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-bordered table-striped" id="order-table">
              <thead>
                  <tr>
                    <th>Symbol</th>
                    <th>Price</th>
                    <th>Amount</th>
                    <th>Filled</th>
                  </tr>
              </thead>
              <tbody>
                <tr v-for="column in orders" :key="column">
                  <td :style="'border-left:5px solid '+(column.positionSide == 'SHORT' ? 'red' : 'green')" class="text-bold">
                    <span></span>{{column.symbol}}</td>
                  <td class="text-right">{{formatNumberAcc(column.price, 4)}}</td>
                  <td :style="'color:'+(column.positionSide == 'SHORT' ? 'red' : 'green')" class="text-right text-bold">{{column.origQty}}</td>
                  <td :style="'color:'+(column.positionSide == 'SHORT' ? 'red' : 'green')" class="text-right text-bold">{{column.executedQty}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { formatNumberAcc, formatNumber } from '../_helpers';
  export default {
    computed: {
        ...mapState('binance_futures_accounts', ['binance_futures_accounts']),
        ...mapState('binance_futures_positions', ['binance_futures_positions']),
    },
    components:{
    },
    data() {
      return {
        positions: [],
        orders: [],
        margin_ratio_txt: '',
        last_wallet_txt: '',
        totalUnrealizedProfit: '',
        totalMarginBalance: '',
        totalWalletBalance: '',
        allTimePnl: '',
        todayPnl: '',
        yesterdayPnl: '',
        thisWeekPnl: '',
        thisMonthPnl: '',
        getAccountInterval: null,
        getPositionInterval: null,
      };
    },
    methods: {
      handleSubmit(){
        if(this.onRequest) return;
      },
      ...mapActions('binance_futures_accounts', {getAccount: 'get_data'}),
      ...mapActions('binance_futures_positions', {getPosition: 'get_data'}),
      formatNumberAcc(value, decimal = 4) {
        let val = parseFloat(value)
        return formatNumberAcc(val, decimal)
      },
      formatNumber(value, decimal = 4) {
        let val = parseFloat(value)
        return formatNumber(val, decimal)
      },
      getStyleColor(val) {
        return val > 0 ? 'color: #28a745' : 'color: #dc3545'
      },
    },
    created() {
      this.getAccount();
      this.getPosition();
      this.getAccountInterval = setInterval(this.getAccount, 5000);
      this.getPositionInterval = setInterval(this.getPosition, 5000);
    },
    unmounted() {
      clearInterval(this.getAccountInterval)
      clearInterval(this.getPositionInterval)
    },
    watch: {
      binance_futures_accounts(next, prev){
        if(next !== prev && next !== null) {
          if(next.account) {
            let margin_ratio = 0
            this.totalUnrealizedProfit = next.account.totalUnrealizedProfit
            this.totalMarginBalance = next.account.totalMarginBalance
            this.totalWalletBalance = next.account.totalWalletBalance
            margin_ratio = this.totalMarginBalance > 0 ? next.account.totalMaintMargin / this.totalMarginBalance * 100 : 0;
            this.allTimePnl = next.allTimePnl
            this.todayPnl = next.todayPnl
            this.yesterdayPnl = next.yesterdayPnl
            this.thisWeekPnl = next.thisWeekPnl
            this.thisMonthPnl = next.thisMonthPnl
  
            if(margin_ratio > 0){
              this.margin_ratio_txt = ' - Margin Ratio: <span class="'+(margin_ratio >= 80 ? 'text-red' : (margin_ratio >= 10 ? 'text-orange' : 'text-green'))+'">'+formatNumber(margin_ratio, 2) + '%</span>'
            }
            if(next.lastWallet){
              let last = next.lastWallet.filter(item => item != parseInt(this.totalUnrealizedProfit / 1000))
              if(last.length > 0){
                this.last_wallet_txt = ' - ' + (last[0] < parseInt(this.totalUnrealizedProfit / 1000) ? '<span class="text-green">' : '<span class="text-red">') + ' ('+last[0]+'K)</span>'
              }
            }
          }
        }
      },
      binance_futures_positions(next, prev){
        if(next !== prev && next !== null && next.binanceFuturePosition && next.binanceFutureOrders) {
          this.positions = next.binanceFuturePosition.filter(item => item.notional !== "0")
          this.orders = next.binanceFutureOrders.filter(item => {
            item.type == 'LIMIT' && ((item.positionSide == 'LONG' && item.side == 'BUY') || (item.positionSide == 'SHORT' && item.side == 'SELL'))
          })
          this.positions.map(item => {
            let order = next.binanceFutureOrders.filter(item2 => item.symbol == item2.symbol)
            const {positionAmt, entryPrice, positionSide, markPrice, leverage} = item
            order.map(item2 => {
              if(item2.type == 'TAKE_PROFIT_MARKET' && item2.positionSide == positionSide){
                item.tp_amt = item2.stopPrice
                item.tp = this.formatNumber(positionAmt * (item2.stopPrice - entryPrice), 2)
              }
              if(item2.type == 'STOP_MARKET' && item2.positionSide == positionSide){
                item.sl_amt = item2.stopPrice
                item.sl = this.formatNumber(positionAmt * (item2.stopPrice - entryPrice), 2)
              }
              if(item2.type == 'LIMIT' && item2.positionSide == positionSide && ((positionSide == 'LONG' && item2.side == 'SELL') || (positionSide == 'SHORT' && item2.side == 'BUY'))){
                item.tp_amt = item2.price
                item.tp = this.formatNumber(positionAmt * (item2.price - entryPrice), 2)
              }
            })
            item.positionInitialMargin = markPrice * Math.abs(positionAmt) / leverage
            item.ppct = (markPrice - entryPrice) * 100 / entryPrice
          })
        }
      },
    }
  }
</script>
