<template>
  <li class="nav-item dropdown">
    <a id="dropdownSubMenu2" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Binance Futures</a>
    <ul aria-labelledby="dropdownSubMenu2" class="dropdown-menu border-0 shadow">
      <li><router-link :class="'dropdown-item'" to="/dashboard">Dashboard</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/binance_future_coins">Binance Futures Coin</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/statistics">Statistics</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/opportunity_risks">Opportunity Risk</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/daily_incomes">Daily Income</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/coin_income_datas">Coin Income Data</router-link></li>
      <li><router-link :class="'dropdown-item'" to="/futures_trades">Trades</router-link></li>
    </ul>
  </li>  
  <li class="nav-item dropdown">
    <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="nav-link dropdown-toggle">Users</a>
    <ul aria-labelledby="dropdownSubMenu1" class="dropdown-menu border-0 shadow">
      <li><router-link :class="'dropdown-item'"  to="/users">Users</router-link></li>
    </ul>
  </li>
</template>

<script>
  import { mapState } from 'vuex'
  import { getCapability } from '../_helpers'
  export default {
    computed: {
        ...mapState('users', ['user'])
    },
    methods:{
      getCapabilityAble(action){
        return getCapability(action, this.user)
      }
    },
  }
</script>
