<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="form-group col-sm-12 text-center">
        <label for="is_position">Only Position:</label>
        <input class="form-control" required type="checkbox" v-model="is_position">
      </div>
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-if="binance_futures_coin" v-bind:entries="coin_data" :columns="columns" :title="'Binance Futures Coin'" :paginate="false" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('binance_futures_coin', ['binance_futures_coin', 'onRequest']),
      ...mapState('binance_futures_positions', ['binance_futures_positions']),
      columns(){
        return [
          {name: 'symbol', text: 'Symbol', raw: {type: 'symbol_image'}},
          {name: 'daily_range', text: 'Daily Range', raw: {type: 'html'}},
          {name: 'pivot_1d', text: 'Pivot Daily', raw: {type: 'html'}},
          {name: 'pivot_1w', text: 'Pivot Weekly', raw: {type: 'html'}},
          {name: 'price', text: 'Price', class: 'text-right', raw: {type: 'compare_color_price_open'}},
          {name: 'open', text: 'Open Price', class: 'text-right'},
          {name: 'high', text: 'High Price', class: 'text-right'},
          {name: 'low', text: 'Low Price', class: 'text-right'},
          {name: 'priceChange', text: 'Price Change', class: 'text-right', raw: {type: 'compare_color_price_open'}},
          {name: 'changePercent', text: '% Change', class: 'text-right', raw: {type: 'compare_color_price_open'}},
          {name: 'volume', text: 'Volume', order: 'desc', class: 'text-right', raw: {type: 'format_number'}},
          ]
        }
    },
    data() {
      return {
        is_position: true,
        coin_data: [],
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('binance_futures_coin', {get_data: 'get_all'}),
      ...mapActions('binance_futures_positions', {getPosition: 'get_data'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_data()
      },
      updateShowCoins() {
        if(!this.binance_futures_coin || !this.binance_futures_positions || !this.binance_futures_positions.binanceFuturePosition) {
          return;
        }
        const {is_position} = this
        if(is_position) {
          this.coin_data = []
          this.coin_data = this.binance_futures_coin.filter(item =>
            this.binance_futures_positions.binanceFuturePosition.some(item2 =>
              item2.symbol === item.symbol
            )
          );
        } else {
          this.coin_data = this.binance_futures_coin
        }
      },
      generateHtml(data, price, open) {
        if (data === null) {
          return "";
        }

        let idx = 0;
        const last = parseFloat(price)
        const isGreen = parseFloat(open) < last;

        if (data.r3 <= last) {
          idx = 0;
        } else if (data.r2 <= last) {
          idx = 1;
        } else if (data.r1 <= last) {
          idx = 2;
        } else if (data.pp <= last) {
          idx = 3;
        } else if (data.s1 <= last) {
          idx = 4;
        } else if (data.s2 <= last) {
          idx = 5;
        } else if (data.s3 <= last) {
          idx = 6;
        } else {
          idx = 7;
        }

        const bgColor = `background-color: ${isGreen ? '#28a745' : '#dc3545'}`;
        const html = `
          <div style="display:flex;height:20px;">
            <span hidden>${idx}</span>
            <div style="display:flex;flex:1;border-left:1px solid green;border-right:1px solid green;${idx === 6 ? bgColor : ''}"></div>
            <div style="display:flex;flex:1;border-right:1px solid green;${idx === 5 ? bgColor : ''}"></div>
            <div style="display:flex;flex:1;border-right:1px solid orange;${idx === 4 ? bgColor : ''}"></div>
            <div style="display:flex;flex:1;border-right:1px solid red;${idx === 3 ? bgColor : ''}"></div>
            <div style="display:flex;flex:1;border-right:1px solid red;${idx === 2 ? bgColor : ''}"></div>
            <div style="display:flex;flex:1;border-right:1px solid red;${idx === 1 ? bgColor : ''}"></div>
          </div>
        `;

        return html;
      },
      progressBarHtml(data) {
        if (!data.high || !data.low) {
          return "";
        }

        const widthVal = data.high - data.low;
        const width = ((data.price - data.low) / widthVal) * 100;

        let progressBarClass = "";
        if (width >= 75) {
          progressBarClass = "bg-success";
        } else if (width >= 45) {
          progressBarClass = "bg-warning";
        } else {
          progressBarClass = "bg-danger";
        }

        return `<div class="progress"><span hidden>${width.toFixed(2)}</span><div class="progress-bar ${progressBarClass}" style="width: ${width.toFixed(2)}%"></div></div>`;
      },
    },
    async created() {
      this.$emit('onChildInit', 'Binance Futures Coin')
      await this.getPosition();
      this.handleSubmit()
    },
    watch: {
      binance_futures_coin(next, prev){
        if(next !== prev && next !== null) {
          next.map(item => {
            item.pivot_1d = this.generateHtml(item.pivotData1d, item.price, item.open)
            item.pivot_1w = this.generateHtml(item.pivotData1w, item.price, item.open)
            item.daily_range = this.progressBarHtml(item)
          })
          this.updateShowCoins()
        }
      },
      is_position(next, prev) {
        if(next !== prev) {
          this.updateShowCoins()
        }
      }
    }
  }
</script>
