import axios from '../_helpers/axios';

export const binance_futures_accountsService = {
  get_data,
};

async function get_data() {
  return new Promise((resolve, reject) => {
    axios.get('binance_futures_account')
    .then(resp => {
        if(resp.data){
          resolve(resp.data)
        }
    }).catch(function(error){
      if (error.response && error.response.data) {
        reject(error.response.data.message)
        return
      }
      reject(error.message)
    })
  })
}