<template>
  <Datatable v-if="coin_income_datas_detail" v-bind:entries="coin_income_datas_detail" :columns="columns" :title="'Coin Income Data' + this.$route.params.symbol" :paginate="false" />
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('coin_income_datas', ['coin_income_datas_detail', 'onRequest']),
      columns(){
        return [
            {name: 'time', text: 'Date', raw: {type: 'Moment_UTC'}, order: 'desc'},
            {name: 'symbol', text: 'Symbol', raw: {type: 'symbol_image'}},
            {name: 'position', text: 'Position'},
            {name: 'qty', text: 'Qty', raw: {type: 'format_number'}, class: 'text-right'},
            {name: 'avgPrice', text: 'AVG Price', raw: {type: 'format_number_acc'}, class: 'text-right'},
            {name: 'income', text: 'Realized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'commission', text: 'Commission', raw: {type: 'format_number_acc_with_color_reverse'}, class: 'text-right'},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  {event: 'futures_trades_detail', text: 'Detail', method: 'get', class: 'btn btn-primary'},
                ]
              }
            },
          ]
        }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('coin_income_datas', {get_data: 'get_detail'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(symbol){
        if(this.onRequest) return;
        this.clear()
        this.get_data(symbol)
      },
    },
    created() {
      this.$emit('onChildInit', 'Coin Income Data ' + this.$route.params.symbol)
      this.handleSubmit(this.$route.params.symbol)
    },
  }
</script>
