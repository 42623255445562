import { coin_income_datasService } from '../_services';

const state = { onRequest: false, coin_income_datas: [], coin_income_datas_detail: [] };

const actions = {
    get_all({ dispatch, commit }) {
        commit('request');

        coin_income_datasService.get_all()
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_detail({ dispatch, commit }, data) {
        commit('request');

        coin_income_datasService.get_detail(data)
            .then(
                (resp) => {
                    commit('success_data_details', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  coin_income_datas: state => {
    return state.coin_income_datas
  },
  coin_income_datas_detail: state => {
    return state.coin_income_datas_detail
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.coin_income_datas = [];
        state.coin_income_datas_detail = [];
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.coin_income_datas = data;
    },
    success_data_details(state, data) {
      state.onRequest = false;
      state.coin_income_datas_detail = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const coin_income_datas = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
