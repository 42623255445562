<template>
  <div class="row">
    <div class="col-sm-6">
      <label for="total_pnl">Total PNL:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_pnl">
    </div>
    <div class="col-sm-6">
      <label for="total_realized_pnl">Total Realized PNL:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_realized_pnl">
    </div>
    <div class="col-sm-4">
      <label for="total_commission">Total Commission:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_commission">
    </div>
    <div class="col-sm-4">
      <label for="total_funding_fee">Total Funding Fee:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_funding_fee">
    </div>
    <div class="col-sm-4">
      <label for="total_insurance_clear">Total Insurance Clear:</label>
      <input class="form-control text-right" disabled type="text" v-model="total_insurance_clear">
    </div>
    <div class="col-sm-12 mt-3">
      <Datatable v-if="coin_income_datas" v-bind:entries="coin_income_datas" :columns="columns" :title="'Coin Income Data'" :paginate="false" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import Datatable from '../components/Datatable.vue'
  import {formatNumberAcc} from '../_helpers/calculation';
  export default {
    computed: {
      ...mapState('users', ['user', 'profile']),
      ...mapState('coin_income_datas', ['coin_income_datas', 'onRequest']),
      columns(){
        return [
            {name: 'symbol', text: 'Symbol', raw: {type: 'symbol_image'}, order: 'asc'},
            {name: 'pnl', text: 'Total PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'realizedPnl', text: 'Realized PNL', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'commission', text: 'Commission', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'fundingFee', text: 'Funding Fee', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'insuranceClear', text: 'Insurance Clear', raw: {type: 'format_number_acc_with_color'}, class: 'text-right'},
            {name: 'dt', text: 'Last Trade', raw: {type: 'Moment_UTC'}},
            {name: 'action', text: 'Action',
              raw: {
                type: 'Action',
                fields: [
                  {event: 'coin_income_data_detail', text: 'Detail', method: 'get_symbol', class: 'btn btn-primary'},
                ]
              }
            },
          ]
        },
    },
    data(){
      return {        
        total_pnl: 0,
        total_realized_pnl: 0,
        total_commission: 0,
        total_funding_fee: 0,
        total_insurance_clear: 0,
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('coin_income_datas', {get_data: 'get_all'}),
      ...mapActions('alert', ['error', 'clear']),
      handleSubmit(){
        if(this.onRequest) return;
        this.clear()
        this.get_data()
      },
    },
    created() {
      this.$emit('onChildInit', 'Coin Income Data')
      this.handleSubmit()
    },
    watch: {
      coin_income_datas(next, prev){
        if(next !== prev && next !== null) {
          this.total_pnl = 0
          this.total_realized_pnl = 0
          this.total_commission = 0
          this.total_funding_fee = 0
          this.total_insurance_clear = 0
          next.map(item => {
            this.total_pnl += item.pnl
            this.total_realized_pnl += item.realizedPnl
            this.total_commission += item.commission
            this.total_funding_fee += item.fundingFee
            this.total_insurance_clear += item.insuranceClear
          })
          this.total_pnl = formatNumberAcc(this.total_pnl)
          this.total_realized_pnl = formatNumberAcc(this.total_realized_pnl)
          this.total_commission = formatNumberAcc(this.total_commission)
          this.total_funding_fee = formatNumberAcc(this.total_funding_fee)
          this.total_insurance_clear = formatNumberAcc(this.total_insurance_clear)
        }
      },
    }
  }
</script>
