import { createApp } from 'vue'
import App from './app/App.vue'
import {router} from './_helpers'
import {store} from './_store'
import { Chart, registerables } from 'chart.js';
import './registerServiceWorker'
Chart.register(...registerables);
const app = createApp(App)
app.use(store)
app.use(router)
app.mount('#app')
