import {createWebHistory, createRouter} from 'vue-router'

import Home from '../home/Home.vue';
import Login from '../login/Login.vue';
import Profile from '../profile/Profile.vue'

import User from '../user/User.vue'
import AddUser from '../user/AddUser.vue'
import EditUser from '../user/EditUser.vue'

import BinanceFuturesTrade from '../binance_futures/BinanceFuturesTrade.vue'
import BinanceFuturesTradeDetail from '../binance_futures/BinanceFuturesTradeDetail.vue'
import CoinIncomeData from '../binance_futures/CoinIncomeData.vue'
import CoinIncomeDataDetail from '../binance_futures/CoinIncomeDataDetail.vue'
import DailyIncome from '../binance_futures/DailyIncome.vue'
import DailyIncomeDetail from '../binance_futures/DailyIncomeDetail.vue'
import Statistic from '../binance_futures/Statistic.vue'
import BinanceFuturesCoin from '../binance_futures/BinanceFuturesCoin.vue'
import BinanceFuturesCoinDetail from '../binance_futures/BinanceFuturesCoinDetail.vue'
import OpportunityRisk from '../binance_futures/OpportunityRisk.vue'
import Dashboard from '../binance_futures/Dashboard.vue'

import NotFound from '../notfound/NotFound.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: 'home',
    children: [
      {
        path: '/profile',
        component: Profile,
        name: 'profile'
      },

      //USERS
      {
        path: '/users',
        component: User,
        name: 'users'
      },
      {
        path: '/users/add',
        component: AddUser,
        name: 'add_user'
      },
      {
        path: '/users/edit/:id',
        component: EditUser,
        name: 'edit_user'
      },

      //BINANCE FUTURES
      {
        path: '/futures_trades',
        component: BinanceFuturesTrade,
        name: 'futures_trades'
      },
      {
        path: '/futures_trades_detail/:id',
        component: BinanceFuturesTradeDetail,
        name: 'futures_trades_detail'
      },
      {
        path: '/coin_income_datas',
        component: CoinIncomeData,
        name: 'coin_income_datas'
      },
      {
        path: '/coin_income_datas/:symbol',
        component: CoinIncomeDataDetail,
        name: 'coin_income_data_detail'
      },
      {
        path: '/daily_incomes',
        component: DailyIncome,
        name: 'daily_incomes'
      },
      {
        path: '/daily_incomes/:dt',
        component: DailyIncomeDetail,
        name: 'daily_income_detail'
      },
      {
        path: '/statistics',
        component: Statistic,
        name: 'statistics'
      },
      {
        path: '/binance_future_coins',
        component: BinanceFuturesCoin,
        name: 'binance_future_coins'
      },
      {
        path: '/binance_futures_coin_detail/:symbol',
        component: BinanceFuturesCoinDetail,
        name: 'binance_futures_coin_detail'
      },
      {
        path: '/opportunity_risks',
        component: OpportunityRisk,
        name: 'opportunity_risks'
      },
      {
        path: '/dashboard',
        component: Dashboard,
        name: 'dashboard'
      },

      { path: '/notFound', component: NotFound },
    ]
  },
  {
    path: '/login',
    component: Login,
    name: 'login'
  },
]

export const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (!to.matched.length) {
    if (loggedIn) {
      next('/notFound');
      return;
    } else {
      next('/login');
      return;
    }
  }
  if(authRequired) {
    if (loggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    if (loggedIn) {
      next('/')
      return
    }
    next()
  }
})
