<template>
  <Datatable v-bind:entries="users" :columns="columns" :title="'Users'"/>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import { isAdmin } from '../_helpers'
  import Datatable from '../components/Datatable.vue'
  export default {
    computed: {
      ...mapState('users', ['users', 'user', 'profile']),
      columns(){
        return [
          {name: 'name', text: 'Name'},
          {name: 'email', text: 'Email'},
          {name: 'apiKey', text: 'Binance API key'},
          {name: 'apiSecret', text: 'Binance Secret Key'},
          {name: 'telegramUserId', text: 'Telegram User ID'},
          {name: 'type', text: 'Type'},
          {name: 'lastLogin', text: 'Last Login',
            raw: {
              type: 'Moment_UTC',
            }
          },
          {name: 'action', text: 'Action',
            raw: {
              type: 'Action',
              fields: [
                {event: 'edit_user', text: 'Edit', method: 'get', class: 'btn btn-primary'},
                isAdmin(this.profile) ? {event: 'users/destroy', text: 'Delete', method: 'post', class: 'btn btn-danger ml-1'} : {},
              ]
            }
          },
        ]
      }
    },
    components:{
      Datatable,
    },
    methods: {
      ...mapActions('users', ['get_all']),
    },
    created() {
      this.$emit('onChildInit', 'Users', '/users/add')
      this.get_all()
    },
  }
</script>
