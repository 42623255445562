import { daily_incomesService } from '../_services';

const state = { onRequest: false, daily_incomes: [], daily_income_detail: {} };

const actions = {
    get_all({ dispatch, commit }, data) {
        commit('request');

        daily_incomesService.get_all(data)
            .then(
                (resp) => {
                    commit('success_datas', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    get_detail({ dispatch, commit }, data) {
        commit('request');

        daily_incomesService.get_detail(data)
            .then(
                (resp) => {
                    commit('success_data_details', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  daily_incomes: state => {
    return state.daily_incomes
  },
  daily_income_detail: state => {
    return state.daily_income_detail
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.daily_incomes = [];
        state.daily_income_detail = {};
    },
    success_datas(state, data) {
      state.onRequest = false;
      state.daily_incomes = data;
    },
    success_data_details(state, data) {
      state.onRequest = false;
      state.daily_income_detail = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const daily_incomes = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
