import { binance_futures_positionsService } from '../_services';

const state = { onRequest: false, binance_futures_positions: {}};

const actions = {
    get_data({ dispatch, commit }) {
        commit('request');

        binance_futures_positionsService.get_data()
            .then(
                (resp) => {
                    commit('success_data', resp);
                },
                error => {
                    commit('error');
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};
const getters = {
  onRequest: state => {
    return state.onRequest
  },
  binance_futures_positions: state => {
    return state.binance_futures_positions
  },
};

const mutations = {
    request(state) {
        state.onRequest = true;
        state.binance_futures_positions = {};
    },
    success_data(state, data) {
      state.onRequest = false;
      state.binance_futures_positions = data;
    },
    error(state) {
        state.onRequest = false;
    },
};

export const binance_futures_positions = {
    namespaced: true,
    state,
    actions,
    getters,
    mutations
};
