/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered () {
      console.log('Service worker has been registered.')
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      console.log('New content is downloading.')
    },
    updated () {
      console.log('New content is available; please refresh.')
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    }
  })
}
navigator.serviceWorker.addEventListener('message', async event => {
  if (event.data && event.data.firebaseMessagingData && event.data.firebaseMessagingData.notification) {
    const reg = await navigator.serviceWorker.getRegistration();
    const {notification} = event.data.firebaseMessagingData
    reg.showNotification(notification.title, {
        body: notification.body,
        icon: './img/icons/icon.png',
        actions: [
            {action: 'open_app', title: 'Open App'},
            {action: 'notification_close', title: 'Close'},
        ],
        badge: './img/icons/icon_notif.png'
    });
  }
})
